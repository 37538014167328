<template>
  <div>
    <b-row class="mb-2">
      <b-col lg="4">
        <label>Filtrar por contrato</label>
        <v-select  class="align-middle inline-block" v-model="contractSelected" :options="listContracts" :reduce="v => v.value" :clearable="false"  @input="getDashboard"/>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="4" md="12">
        <b-card class="card_statisc">
          <b-row>
            <b-col
              md="12"
              class="mb-2 mt-2"
            >
              <statistic-card-vertical
                icon="ClockIcon"
                :statistic="totalMeters"
                statistic-title="Total de tarjetas de escalamiento"
                color="info"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col lg="4" md="12">
        <b-card class="card_statisc">
          <b-row>
            <b-col
              md="12"
              class="mb-2 mt-2"
            >
              <statistic-card-vertical
                icon="ClockIcon"
                :statistic="totalMetersInstalled"
                statistic-title="Total de tarjetas de escalamiento (Instaladas)"
                color="success"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col lg="4" md="12">
        <b-card class="card_statisc">
          <b-row>
            <b-col
              md="12"
              class="mb-2 mt-2"
            >
              <statistic-card-vertical
                icon="ClockIcon"
                :statistic="totalMetersNotInstalled"
                statistic-title="Total de tarjetas de escalamiento (No Instaladas)"
                color="danger"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col lg="4" md="12">
        <b-card class="card_statisc">
          <b-row>
            <b-col
              md="12"
              class="mb-2 mt-2"
            >
              <statistic-card-vertical
                icon="WifiIcon"
                :statistic="transmittingLast24"
                statistic-title="Medidores que transmitieron antes de 24 horas"
                color="success"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col lg="4" md="12">
        <b-card class="card_statisc">
          <b-row>
            <b-col
              md="12"
              class="mb-2 mt-2"
            >
              <statistic-card-vertical
                icon="WifiIcon"
                :statistic="last24And48"
                statistic-title="Medidores que transmitieron antes de 48 horas"
                color="success"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col lg="4" md="12">
        <b-card class="card_statisc">
          <b-row>
            <b-col
              md="12"
              class="mb-2 mt-2"
            >
              <statistic-card-vertical
                icon="WifiIcon"
                :statistic="last48And72"
                statistic-title="Medidores que transmitieron antes de 72 horas"
                color="warning"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col lg="4" md="12">
        <b-card class="card_statisc">
          <b-row>
            <b-col
              md="12"
              class="mb-2 mt-2"
            >
              <statistic-card-vertical
                icon="WifiIcon"
                :statistic="installedTransmittingMeters"
                statistic-title="Medidores instalados que transmitieron antes de 24 horas"
                color="success"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col lg="4" md="12">
        <b-card class="card_statisc">
          <b-row>
            <b-col
              md="12"
              class="mb-2 mt-2"
            >
              <statistic-card-vertical
                icon="RadioIcon"
                :statistic="installedTransmittingMeters"
                statistic-title="Medidores instalados transmitiendo a SiNaMed"
                color="success"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col lg="4" md="12">
        <b-card class="card_statisc">
          <b-row>
            <b-col
              md="12"
              class="mb-2 mt-2"
            >
              <statistic-card-vertical
                icon="WifiOffIcon"
                :statistic="installedNotTransmittingMeters"
                statistic-title="Medidores instalados que transmitieron después de 24 horas"
                color="danger"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col md="12" >
        <b-card>
          <div class="text-center">
            <b>Medidores por Zonas</b>

            <b-row>
              <b-col cols="12" md="3">
                  <v-select input-id="zona" :options="listZonas" placeholder="Todas las Zonas" :clearable="false" v-model="zoneUuid" @input="getDashboard"/>
              </b-col>

              <b-col cols="12" md="9" class="text-center d-flex justify-content-end">
                <b-button
                  v-if="urlDownload.length > 0 && responseData.length > 0"
                  :href="urlDownload"
                  download="medidores_conteo.csv"
                  variant="outline-primarySpore">
                  Descargar CSV
                </b-button>
              </b-col>
            </b-row>

          </div>
          <vue-apex-charts
            v-if="zoneChart.ready"
            type="bar"
            height="400"
            :options="zoneChart.chartOptions"
            :series="zoneChart.series"
          />
        </b-card>
      </b-col>

      <!--
      <b-col cols="12" md="3" v-for="(chart,index) in this.donutsChart" v-bind:key="index" >
        <b-card :title="chart.title">
          <vue-apex-charts
            v-if="chart.ready"
            type="donut"
            height="350"
            :options="chart.options"
            :series="chart.series"
          />
        </b-card>
      </b-col>
      -->
    </b-row>
  </div>
</template>
<script>
import {
  BCard, BRow, BCol, BButton
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { empty } from '@/libs/tools/helpers'
import request from '@/libs/request/index'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'

export default {
  components: {
    BCard,
    VueApexCharts,
    BRow,
    BCol,
    BButton,
    StatisticCardVertical
  },
  data () {
    return {
      contractSelected: '',
      zoneUuid: '',
      listZonas: [{ title: '', value: '', label: '' }],
      listContracts: [
        { label: 'Todos', value: '' }
      ],
      urlDownload: '',
      responseData: [],
      totalCabinet: 0,
      transmitting: 0,
      NoTransmiting: 0,
      totalMeters: 0,
      totalMetersInstalled: 0,
      totalMetersNotInstalled: 0,
      transmittingMeters: 0,
      NoTransmitingMeters: 0,
      installedTransmittingMeters: 0,
      installedNotTransmittingMeters: 0,
      transmittingLast24: 0,
      last24And48: 0,
      last48And72: 0,
      distincMeters: 0,
      zoneChart: {
        ready: false,
        series: [
          {
            name: 'Medidores',
            data: []
          }
        ],
        chartOptions: {
          chart: {
            stacked: true,
            toolbar: {
              show: false
            }
          },
          plotOptions: {
            bar: {
              columnWidth: '35%',
              colors: {
                backgroundBarRadius: 5
              }
            }
          },
          dataLabels: {
            enabled: false
          },
          legend: {
            show: true,
            position: 'top',
            fontSize: '14px',
            fontFamily: 'Montserrat',
            horizontalAlign: 'left'
          },
          stroke: {
            show: true,
            colors: ['transparent']
          },
          grid: {
            xaxis: {
              lines: {
                show: true
              }
            }
          },
          xaxis: {
            categories: [],
            title: {
              text: 'Zonas'
            }
          },
          yaxis: {
            title: {
              text: 'Medidores'
            },
            labels: {
              formatter: function (val) {
                return val.toFixed(0)
              }
            }
          },
          fill: {
            opacity: 1
          }
        }
      },
      donutsChart: [],
      rangePicker: []
    }
  },
  methods: {
    async getContracts () {
      const params = {
        url: '/contracts',
        method: 'GET',
        params: { divisionUuid: this.$store.state.session.AppActiveUser().division.uuid }
      }
      await request(params).then(data => {
        if (data.status === 200) {
          for (const item of data.data) {
            this.listContracts.push({
              label: `Contrato ${item.contract}`,
              value: item.contract
            })
          }
        }
      })
    },
    async getDashboard () {
      this.zoneChart.series[0].data = []
      this.responseData = []
      this.zoneChart.chartOptions.xaxis.categories = []
      this.zoneChart.ready = false
      const params = {
        url: 'report/general/079',
        method: 'GET',
        params: {
          divisionUuid: this.$store.state.session.AppActiveUser().division.uuid,
          zoneUuid: (this.zoneUuid.value === undefined ? '' : this.zoneUuid.value),
          contract: this.contractSelected
        }
      }
      await request(params).then(response => {
        if (!empty(response.data.meters)) {
          this.totalMeters = response.data.meters[0].total
          this.totalMetersInstalled = response.data.meters[0].installed
          this.totalMetersNotInstalled = response.data.meters[0].notInstalled
          this.transmittingMeters = response.data.meters[0].transmitting
          this.NoTransmitingMeters = response.data.meters[0].no_transmitting
          this.distincMeters = response.data.meters[0].distinct_numbers
          this.installedTransmittingMeters = response.data.meters[0].installedTransmitting
          this.installedNotTransmittingMeters = response.data.meters[0].installedNotTransmitting
          this.transmittingLast24 = response.data.meters[0].transmittingLast24 ?? 0
          this.last24And48 = response.data.meters[0].last24And48
          this.last48And72 = response.data.meters[0].last48And72
        }

        if (!empty(response.data.devicesZones)) {
          response.data.devicesZones.forEach((element, index) => {
            this.zoneChart.series[0].data.push({
              x: element.name,
              y: parseInt(element.total),
              fillColor: '#00c3c3'
            })
          })
          this.responseData = response.data.devicesZones
          this.zoneChart.ready = true
        }

        this.createCsv()
      })
    },
    createCsv () {
      const csv = []
      const headers = []
      headers.push('Estatus')
      headers.push('Total de Medidores')
      csv.push(headers.join(','))
      this.responseData.map(item => {
        const row = []
        row.push(item.name)
        row.push(item.total)
        csv.push(row.join(','))
        return item
      })
      const file = csv.join('\n')
      const csvFile = new Blob([file], { type: 'text/csv' })
      this.urlDownload = window.URL.createObjectURL(csvFile)
    },
    async getZonas () {
      const params = {
        url: '/zones',
        method: 'GET',
        params: { divisionUuid: this.$store.state.session.AppActiveUser().division.uuid }
      }
      await request(params).then(data => {
        if (data.status === 200) {
          this.listZonas.push({
            title: 'Todas las Zonas',
            value: '',
            label: 'Todas las Zonas'
          })
          for (const item of data.data) {
            this.listZonas.push({
              title: item.name,
              value: item.uuid,
              label: item.name
            })
          }
        }
      })
    }
  },
  created () {
    this.getContracts()
    this.getZonas()
    this.getDashboard()
  }
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  @import '@core/scss/vue/libs/chart-apex.scss';
</style>
